<template>
  <div>
    <div class="p2-notice" v-if="questionnaires">
      <div class="title">{{ riskDatas.riskRemindTitle }}</div>
      <p class="left">
        <strong>风险提示：</strong>
      </p>
      <p class="text" v-html="riskDatas.riskRemindContent"></p>
      <div class="ui-btn-wrap bottom">
        <button class="button" @click="toTopic">下一步</button>
      </div>
    </div>
    <div class="p2-test q1 qa13" style="padding-bottom: 3rem;" v-if="reviewTopic">
      <div class="process-cont">
        <div class="process" :style="progress"></div>
      </div>

      <div v-for="(item,index) in riskDatas.subjects" :key="index" v-show="titleNum == index">
        <div class="num">
          <b>{{ index + 1 }}</b>
          /{{ riskDatas.totalSubjects }}
        </div>
        <div class="title">
          {{ item.title }}
          <span v-if="item.isCheckbox == 1">(可多选)</span>:
        </div>
        <ul class="test-option" :data-checkBox="item.isCheckbox">
          <li
            v-for="(subItem,ins) in item.options"
            :key="ins"
            @click="chooseAnswer(ins,item.options,item)"
            :class="{'chosen':subItem.isSelect == true }"
           
          >{{ subItem.optionNumber }}、{{ subItem.optionContent }}</li>
        </ul>
        <div class="btnArea">
          <div class="prevBtn" v-if="titleNum !== 0" @click="preTitle">上一题</div>
          <div
            class="nextBtn"
            @click="nextTitle(titleNum)"
            v-if="titleNum < (riskDatas.totalSubjects - 1)"
          >下一题</div>
        <div class="nextBtn" @click="subAnswer()" v-else>下一步</div>
      </div>
      </div>
    </div>
    <div class="p3-sub" style="height: 100%;padding-top:0;" v-if="subAns">
     
       <div class="p2-notice" style="margin-top:1rem">
          <p class="text" v-html="riskDatas.disclaimer"></p>
        </div>
      <div class="tooltips s" style="margin-top:2rem;">
        <input type="checkbox" v-model="checked" style="margin-left: 0.8rem; margin-top: .2rem;" />
        <span>
          <strong v-html="riskDatas.investorPromise"></strong>
        </span>
      </div>
      <div class="cont" style="padding:0;"></div>
      <div class="ui-btn-wrap bottom">
        <a class="button" @click="toSumit">提交签署</a>
      </div>
      <div class="link">
        <a @click="lookScore">查看评分标准</a>
        <a @click="reTest">重新测评</a>
      </div>
    </div>
    <div class="p2-result" v-if="testResult">
      <div class="result">
        <p class="center">
          （评估结果：经过测试，您共得
          <b>{{ riskResult.scores }}</b>分)
        </p>
        <h1>{{ riskResult.InvestorTypeStr }}</h1>
        <p class="tips">请注意查收短信，点击短信中的链接，完成电子签约！</p>
        <!-- <div class="link">
          <a @click="reTest1">重新测评</a>
        </div> -->
        <div>
          <span class="button" @click="toSign">返回签约管理</span>
        </div>
      </div>
    </div>
  <!--测评标准--->
  <div class="score" v-if="isScore"> 
    <span class="scoreClose" @click="closeScore">x</span>
    <div class="title">评分标准</div>
 	<div class="min-titles">桂澜山资管公司备案产品投资者风险测评问卷（个人版）评分表</div>
		<table border="1">
			<tr>
				<td>题目</td>
				<td>除第12、13题之外其余题目</td>
				<td>第12题</td>
				<td>第13题</td>
			</tr>
			<tr>
				<td>分值</td>
				<td>A：2分</br>B：4分</br>C：6分</br>D：8分</br>E：10分</td>
				<td>A:0分</br>B-E任意一项：2分</br>B-E任意两项：4分</br>B-E任意三项：6分</br>B-E四项：8分</br></td>
				<td>A-C任意一项：2分</br>A-C任意两项：4分</br>A-C三项：6分</br>D:0分</td>
			</tr>
		</table>
		<p class="min-tit">投资者与桂澜山资管公司备案产品产品风险等级匹配表</p>
		<table border="1">
			<tr>
				<td>风险偏好</td>
				<td>分数统计</td>
				<td>适配的产品等级</td>
			</tr>
			<tr>
				<td>C1保守型</td>
				<td>[16,30]</td>
				<td>R1低风险</td>
			</tr>
			<tr>
				<td>C2稳健型</td>
				<td>[30,45]</td>
				<td>R2中低风险</td>
			</tr>
			<tr>
				<td>C3平衡型</td>
				<td>[45,60]</td>
				<td>R3中风险</td>
			</tr>
			<tr>
				<td>C4成长型</td>
				<td>[60,80]</td>
				<td>R4中高风险</td>
			</tr>
			<tr>
				<td>C5进取型</td>
				<td>[80,100]</td>
				<td>R5高风险</td>
			</tr>
		</table>
	<!-- <p>产品风险级别（由低到高）：R1-谨慎型 R2-稳健型 R3-平衡型 R4-进取型 R5-激进型</p> -->
  </div>
  <!--测评标准end--->
  </div>
</template>
<script>
// import basic from "../../basic";
// import riskDat from "../riskTest.json";

import { getRiskAssessmentInfo,saveRiskEvaluationResult} from "../services/proApi";
export default {
  data() {
    return {
      load:false,
      mulArr: [],
      riskDatas: {},
      productId: "",
      questionnaires: true,
      userInfo: false,
      reviewTproduopic: false,
      subAns: false,
      testResult: false,
      userInfoData: {},
      titleWapper: {
        display: "block"
      },
      progress: {
        width: ""
      },
      titleNum: 0,
      isShow: false,
      isactive: "-1",
      checked: "",
      optionAggregate: [],
      scoreAggregate: [],
      riskResult: {},
      subjectIds:[],
      user:"",
      userPhone:"",
      reviewTopic:false,
      pre:[],
      titleArr:[],
      selectTitleArr:[],
      isClick:false,
      address:"",
      clickNum:1,//上一题点击次数
      proIsClick:false,
      reserveId:'',
      isScore:false,
    };
  },
  methods: {
    //去做题模块
    toTopic() {
      this.questionnaires = false;
      this.reviewTopic = true;
    },
    //选择
    chooseAnswer(ins, subItem, item) {
      this.isClick = true
      //单项选择
      if (item.isCheckbox == 0) {
         subItem.map((child, index) => {
          child.isSelect = false;
        });
        subItem[ins].isSelect = true;
        this.isactive = ins;
        this.$forceUpdate()
      }
      //多项选择
      else {
        if (this.mulArr.includes(ins)) {
          //includes()方法判断是否包含某一元素,返回true或false表示是否包含元素，对NaN一样有效
          //filter()方法用于把Array的某些元素过滤掉，filter()把传入的函数依次作用于每个元素，然后根据返回值是true还是false决定保留还是丢弃该元素：生成新的数组
          this.mulArr = this.mulArr.filter(function(ele) {
            return ele != ins;
          });
          //filter()为假时删除
        } else {
          this.mulArr.push(ins);
        }
        subItem[ins].isSelect = ! subItem[ins].isSelect;
        this.$forceUpdate()
      }
    },
    //上一题
    preTitle() {
      this.proIsClick = true
      this.clickNum++
      console.log(this.clickNum)
      this.titleNum--
      this.progress.width = `calc(${100 / this.riskDatas.totalSubjects}% + 
      ${this.titleNum}*${100 / this.riskDatas.totalSubjects}%)`;
    },
    //下一题
    nextTitle(titleNum) {
      if(this.clickNum != 1){
        this.isClick = true
        this.clickNum--
        console.log(this.clickNum)
      }
      if(this.clickNum == 1){
        this.proIsClick = false
         setTimeout(()=>{
           this.isClick = false
         },100)
      }
       if(this.isClick) {
        this.titleNum++;
        this.progress.width = `calc(${100 / this.riskDatas.totalSubjects}% + 
        ${this.titleNum
        }*${100 / this.riskDatas.totalSubjects}%)`;
      } else {
       this.$toast('请选择答案');
      }
    },
    //提交答案
    subAnswer() {
      if (this.isactive == "-1" && this.mulArr.length == 0) {
         this.$toast('请选择答案');
      } else {
        this.reviewTopic = false;
        this.subAns = true;
      }
    },
    //重新测评
    reTest() {
      // location.reload()
       this.optionAggregate = [],
       this.scoreAggregate = [],
       this.subjectIds = [],
       this.questionnaires = true;
       this.subAns = false;
       this.titleNum = 0
       this.riskDatas.subjects.map((item, index) => {
            this.subjectIds.push(item.riskEvaluationTitleId)
            item.options.map((child, idx) => {
              child.isSelect = false;
            });
        });
    },
    //提交签署
    toSumit() {
       if (this.checked == false) {
         this.$toast('请先确认并勾选');
      } else {
        this.riskDatas.subjects.map((item, index) => {
          let mullArr = "";
          let scoreArr = "";
          item.options.map((child, inx) => {
            if (item.isCheckbox == 0) {
              if (child.isSelect) {
                this.optionAggregate.push(child.optionNumber);
                this.scoreAggregate.push(child.optionScore);
              }
            } else {
              if(item.scoreRule == 2){
                if (child.isSelect) {
                   if (mullArr != "") {
                      mullArr += "_";
                    }
                    mullArr += child.optionNumber;
                    if(scoreArr== ""){
                          scoreArr = child.optionScore;
                    }
                    if(Number(child.optionScore)> Number(scoreArr)){
                        scoreArr = child.optionScore;
                    }
                }
              }else{
                if (child.isSelect) {
                    if (mullArr != "") {
                      mullArr += "_";
                    }
                    mullArr += child.optionNumber;
                    if (scoreArr != "") {
                      scoreArr += "_";
                    }
                    scoreArr += child.optionScore;
                }
              }
              
            }
          });
          if (mullArr != "") {
            this.optionAggregate.push(mullArr);
          }
          if (scoreArr != "") {
            this.scoreAggregate.push(scoreArr);
          }
        });
        console.log(this.optionAggregate.toString());
        console.log(this.scoreAggregate.toString());
        console.log(this.subjectIds.toString());
        this.testResult = true;
        this.subAns = false;
        this.saveRiskEvaluationResult();//保存测评结果
      }
    },
    toSign(){
      this.$router.push({
        path: '/signList',
        query: {
          notResidentIDCard: true
        }
      });
      
    },
    async getRiskAssessmentInfo(reserveId){
       const data =await getRiskAssessmentInfo({reserveId: reserveId});
      if (data.code === 1) {
        this.riskDatas = data.data;
        if(this.riskDatas.agencyContractor){
          this.user = this.riskDatas.agencyContractor
        }
        if(this.riskDatas.agencyContractorPhone){
          this.userPhone = this.riskDatas.agencyContractorPhone
        }
        if(this.riskDatas.agencyContractorAddress){
          this.address = this.riskDatas.agencyContractorAddress
        }
        this.progress.width = `calc(${100 / this.riskDatas.totalSubjects}%)`;
    
        this.riskDatas.subjects.map((item, index) => {
          this.subjectIds.push(item.riskEvaluationTitleId)
          item.options.map((child, idx) => {
            child.isSelect = false;
          });
        });
      }
    },
    async saveRiskEvaluationResult(){
       //保存测评结果
      const datas =await saveRiskEvaluationResult({
        userPhone:this.userPhone,
        optionAggregate:this.optionAggregate.toString(),
        scoreAggregate:this.scoreAggregate.toString(),
        // subjectIds:this.subjectIds.toString(),
        reserveId:this.reserveId,
      });
      if (datas.code === 1) {
        this.riskResult = datas.data;
      }
    },
    lookScore(){
      this.isScore=true;
    },
    closeScore(){ this.isScore=false;},
  },
  watch: {},
  mounted() {
    var reserveId=this.$route.query.reserveId;
    this.reserveId=reserveId;
    this.getRiskAssessmentInfo(reserveId);
  }
};
</script>
<style lang="scss" >
.loading {z-index: 11;width: 100%;height: 100%;position: fixed;top: 0;left: 0;background: rgba(140, 139, 139, 0.7);
    img{width: 15%;position: fixed;top: 40%;left: 50%;margin-left: -7.5%;}
}
.p2-notice {
  background: #fff;padding-bottom:1rem;
  .bottom {
    position: relative;
  }
  .title {
    padding-top: 1rem;
    color: #333;
    font-size: 1rem;
    text-align: center;
    line-height: 1rem;
  }
  .min {
    padding: 0 1rem;
    font-size: 0.8rem;
    color: #356bff;
    text-decoration: underline;
    margin-top: 2.2rem;
    margin-bottom: 2rem;
  }
  p {
    padding: 0 1rem;
    color: #333;
    font-size: 0.8rem;
    text-indent: 1.4rem;
    margin-bottom: 1.2rem;
    line-height:1.4rem;
  }
  .left {
    text-align: left;
    text-indent: 0;
    margin-top: 1rem;
  }
  .ui-btn-primary {
    background: -webkit-linear-gradient(left, #0398ff, #356bff);
    border: none;
  }
}
.p2 {
  position: absolute;
  height: 100%;
  width: 100%;
  .ui-form-item {
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    .ui-txt-info {
      padding-right: 0.9rem;
      font-size: 0.8rem;
      float: right;
      color: #666;
      line-height: 24px;
      align-items: center;
    }
    .labels {
      color: #000;
      font-size: 0.8rem;
    }
    .content-info {
      float: right;
      font-size: 0.7rem;
      color: #666;
      margin-top: -2rem;
    }
    .ui-txt-info.more {
      text-align: right;
    }
    .inputArea {
      direction: ltr;
      padding-left: 0;
      width: 12.5rem;
      text-align: right;
      padding-right: 0.9rem;
    }
    .ui-icon-close {
      display: none;
      top: auto;
    }
  }
  .ui-btn-primary {
    background: -webkit-linear-gradient(left, #0398ff, #356bff);
    border: none;
  }
}
.p2-test .process-cont {
  width: 100%;
  height: 0.3rem;
  background: #ddd;
}
.p2-test .process-cont .process {
  background: linear-gradient(to right, #356bff 0%, #ff5400 100%);
  height: 0.3rem;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.p2-test {
  font-size: 0.82rem;
  background: #fff;
  min-height: 100%;
  position: absolute;
  width: 100%;
}
.p2-test .num {
  font-size: 0.9rem;
  color: #999;
  padding: 0.8rem 1rem 0.5rem;
}
.p2-test .num b {
  font-size: 1.1rem;
  color: #ff5400;
}
.p2-test .title {
  color: #356bff;
  padding: 0 1rem 1rem;
  text-align: left;
  font-size:1rem;
  line-height:3rem;
}

.p2-test .test-option li {
  padding: 1rem 1rem;font-size:1rem;line-height:1.2rem;
}
.p2-test .test-option .chosen {
  background: #356bff;
  color: #fff;
}
.p2-test .test-options {
  width: 100%;
}
.p2-test .test-options .selected {
  background: #356bff;
  color: #fff;
}
.p2-test .test-options .multiSelect {
  width: 100%;
  padding: 1rem;
  font-size: 0.82rem;
  box-sizing: border-box;
}
.p2-test .test-options .multiSelect span {
  margin-left: 0.5rem;
}
.p2-test .test-options .multiSelect input[type="checkbox"] {
  zoom: 120%;
}
.p2-test .test-options .multiSelects {
  width: 100%;
  padding: 1rem;
  font-size: 0.82rem;
  box-sizing: border-box;
}
.p2-test .test-options .multiSelects span {
  margin-left: 0.5rem;
}
.p2-test .test-options .multiSelects input[type="checkbox"] {
  zoom: 120%;
}
.p2-test .btnArea {
  position: fixed;
  bottom: 1.5rem;
  bottom: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.p2-test .nextBtn {
  width: 7rem;
  background: #f4f4f4;
  color: #333;
  font-size: 0.9rem;
  text-align: center;
  border-radius: 1rem;
  padding: 0.6rem 0;
}
.p2-test .nextBtns {
  width: 7rem;
  background: #f4f4f4;
  color: #333;
  font-size: 0.9rem;
  text-align: center;
  border-radius: 1rem;
  padding: 0.6rem 0;
}
.p2-test .prevBtn {
  width: 7rem;
  background: #f4f4f4;
  color: #333;
  font-size: 0.9rem;
  text-align: center;
  border-radius: 1rem;
  padding: 0.6rem 0;
}
.p2-test .nextBtn:hover {
  background: #cdcdce;
}
.p2-test .prevBtn:hover {
  background: #cdcdce;
}
.p2-test .nextBtn.primary {
  color: #356bff;
}
.p3-sub {
  height: 100%;
  width: 100%;
  position: absolute;
  background: #fff;
  padding-top: 10%;
}
.p3-sub .tooltips span {
  color: #666;line-height: 1.2rem;
}
.p3-sub .tooltips.s i {
  // background: url(../../assets/icon.png) no-repeat;
  width: 1.6rem;
  height: 1.6rem;
  display: inline-block;
  background-position: -2.2rem -16.3rem;
  transform: scale(0.6);
  margin-bottom: -0.5rem;
}
.p3-sub .tooltips span{ width: 87%;  display: inline-block;  float: right;    margin-right: 2%;}

.p3-sub .tooltips.s i.uncheck {
  background-position: 0rem -16.3rem;
}
.p3-sub .link {
  margin-top: 1rem;
  font-size: 0.7rem;
  display: flex;
  justify-content: center;
  padding: 0 1rem;
}
.p3-sub .link a {
  color: #356bff;
  font-size: 0.9rem;
}
.p3-sub .link a:first-child{margin-right:10rem;}
.p3-sub .bottom {
  width: 93.5%;margin:0 auto;
}
.p3-sub .bottom a{
  display: inline-block;
}
.goIndex {
  margin: 1rem auto;
  width: 90%;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  font-size: 1rem;
  background: -webkit-linear-gradient(left, #0398ff, #356bff);
  border-radius: 5px;
  display: block;
  color: #fff;
}
.p3-sub .cont {
  color: #333;
  font-size: 0.72rem;
  padding: 1rem;
  text-indent: 1.2rem;
  line-height: 1.2rem;
}
.p3-sub .notice {
  font-size: 0.8rem;
  padding: 0 0.5rem;
  color: #666;
  line-height: 1.5rem;
  margin-bottom: 1rem;
}
.p3-sub .ui-btn-primary {
  background: -webkit-linear-gradient(left, #0398ff, #356bff);
  border: none;
}
.p2-result.st {
  display: block;
}
.p2-result .result {
  background: #fff;
  padding: 1.7rem 1.2rem;
}
.p2-result .result h1 {
  font-size: 2rem;
  text-align: center;
  color: #333;
  margin:2rem;
}
.p2-result .result b {
  color: #356bff;
}
.p2-result .result p {
  color: #666;
  font-size: 0.8rem;
  line-height: 1.4rem;
}
.p2-result .result p.center {
  text-align: center;
  margin: 1rem 0;
}
.p2-result .tooltips {
  color: #999;
  margin-top: 1rem;
}
.p2-result .tooltips i {
  // background: url(../../assets/icon.png) no-repeat;
  width: 1.6rem;
  height: 1.6rem;
  display: inline-block;
  background-position: -2.2rem -16.3rem;
  transform: scale(0.6);
  margin-bottom: -0.5rem;
}
.check {
  margin-left: 0.8rem;
}
.p2-result .tooltips i.uncheck {
  background-position: 0rem -16.3rem;
}
.p2-result .bottom {
  position: initial;
  width: 93.5%;
}
.p2-result .link {
  margin-top: 1rem;
  font-size: 0.7rem;
  display: flex;
  justify-content: center;
  padding: 0 1rem;
}
.p2-result .link a {
  color: #356bff;font-size:0.9rem;
}
.tips{text-align: center;font-size:0.9rem;margin:2rem 0;}
.ui-form{background: #fff;}
.ui-form-item {
    position: relative;
    height: 50px;
    line-height: 50px;
    padding-right: 12px;
    padding-left: 12px;
    border-bottom:0.01rem solid #e9e9e9;
    input{border:none}
}
.min-titles{
  font-size: .9rem;
  color: #0062FF;
  padding-bottom: 1rem;
  line-height:1.2rem;
}
.min-tit{
  font-size: .8rem;
  color: #0062FF;
  padding-top: 1rem;
}
.score{padding: 2rem 1rem;color: #333;background: #fff;position: relative;}
.score .scoreClose{
  background: #ccc;
    width: 2rem;
    display: inline-block;
    height: 2rem;
    border-radius: 5rem;
    text-align: center;
    color: #fff;
    line-height: 1.8rem;
    font-size: 1.2rem;
    position: absolute;
    right: 1rem;
    top: 1rem;}
.score .title{font-size: 1.2rem;text-align: center;    margin-bottom: 2rem;}
.score .scores{color: #356BFF; color: #356BFF;height: 2rem;    display: flex;  justify-content: center;}
.score .scores li{float: left; font-size: .9rem; margin-right: .8rem;}
.score li{font-size: 0.8rem;}
.score .min-title{color: #356BFF;font-size: .9rem;}
.score p{margin: 1rem 0;font-size: 0.8rem;}
.score p b{color: #356BFF;}
.score table{ border:1px; border-collapse: collapse;border-spacing: 0;}
.score table tr{width: 100%;}
.score  table td{vertical-align: middle;width: 25%;font-size: .8rem;text-align: center;border: 0.01px solid #ddd;padding:0.5rem;line-height: 1.2rem;}
</style>